export const environment = {
  production: false,
  tenantName: 'dev1',
  database: 'verify',
  functionRegion: 'europe-west1',
  firebaseConfig: {
    apiKey: 'AIzaSyC-R2OqM0s4_quXIqMYWKXLrtIB41o_GwY',
    authDomain: 'fir-apps-dev-c730f.firebaseapp.com',
    projectId: 'fir-apps-dev-c730f',
    storageBucket: 'fir-apps-dev-c730f.appspot.com',
    messagingSenderId: '125694536324',
    appId: '1:125694536324:web:8c706886bfafdb3e78b0d1',
    measurementId: 'G-NCRHYSVYS8',
  },
};
