import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@verify/shared-components/models';
import { AuthService } from '@verify/shared-components/services';
import { UserService } from '../../../services';

@Component({
    selector: 'app-profile',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
    ],
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  private authService = inject(AuthService);
  private formBuilder = inject(FormBuilder);
  private userService = inject(UserService);
  private snackBar = inject(MatSnackBar);

  readonly form = this.formBuilder.nonNullable.group({
    firstName: [''],
    lastName: [''],
    email: ['', Validators.required],
  });

  readonly passwordForm = this.formBuilder.nonNullable.group({
    password: ['', Validators.required],
    repeatPassword: ['', Validators.required],
  });

  ngOnInit(): void {
    this.form.patchValue(this.authService.currentUser);
  }

  get user(): User {
    return this.authService.currentUser;
  }

  onSave(): void {
    const { firstName, lastName, email } = this.form.value;

    if (email.length > 0 && email !== this.authService.currentUser.email) {
      this.authService.updateEmail(email);
    }
    this.userService.updateUser({ ...this.user, firstName, lastName, email });
    this.snackBar.open(`User updated successfully`, null, { duration: 3000 });
  }

  onUpdatePassword(): void {
    const { password, repeatPassword } = this.passwordForm.value;
    if (password.length > 0 && password === repeatPassword) {
      this.authService.updatePassword(password);
      this.snackBar.open(`Password changed successfully`, null, {
        duration: 3000,
      });
    }
  }
}
