<mat-sidenav-container class="layout-container">
  <mat-sidenav class="layout-sidenav" opened fixedInViewport="true" mode="side">
    <div class="layout-sidenav-container">
      <img class="layout-sidenav-logo" src="assets/verify-logo-white.png" />

      <div class="layout-sidenav-menu">
        <ng-container *ngIf="tenantId">
          <button
            class="layout-sidenav-menu-item"
            [routerLink]="['/dashboard']"
            routerLinkActive="layout-sidenav-menu-item-active"
          >
            <mat-icon class="layout-sidenav-menu-item-icon">dashboard</mat-icon>
            Dashboard
          </button>
          <button
            class="layout-sidenav-menu-item"
            [routerLink]="['/usage']"
            routerLinkActive="layout-sidenav-menu-item-active"
          >
            <mat-icon class="layout-sidenav-menu-item-icon"
              >monitoring</mat-icon
            >
            Usage
          </button>
          <button
            class="layout-sidenav-menu-item"
            [routerLink]="['/api-keys']"
            routerLinkActive="layout-sidenav-menu-item-active"
          >
            <mat-icon class="layout-sidenav-menu-item-icon">key</mat-icon>
            API Keys
          </button>
        </ng-container>
        <ng-container *ngIf="!tenantId">
          <button
            class="layout-sidenav-menu-item"
            [routerLink]="['/tenants']"
            routerLinkActive="layout-sidenav-menu-item-active"
          >
            <mat-icon class="layout-sidenav-menu-item-icon">store</mat-icon>
            Tenants
          </button>
        </ng-container>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="layout-content">
    <div class="layout-content-header">
      <ng-container *ngIf="user">
        <div class="profile-icon">{{ initials }}</div>
        <h6 class="profile-name">{{ user | userName }}</h6>
      </ng-container>
      <button mat-icon-button [matMenuTriggerFor]="profileMenu">
        <mat-icon> arrow_drop_down </mat-icon>
      </button>
      <mat-menu #profileMenu="matMenu">
        <ng-container *ngIf="user">
          <button mat-menu-item routerLink="/settings/profile">
            <mat-icon>person</mat-icon>
            User profile
          </button>
          <button
            *ngIf="UserRule.userManagement | hasRule"
            mat-menu-item
            routerLink="/settings/users"
          >
            <mat-icon>group</mat-icon>
            User Management
          </button>
        </ng-container>
        <button mat-menu-item (click)="onLogout()">
          <mat-icon> logout </mat-icon>
          Logout
        </button>
      </mat-menu>
    </div>
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
