<ng-container *ngIf="tenantId">
  <h4>Verify Partner Portal</h4>
  <p>
    Welcome to Verify Partner Portal. Currently you can find your API keys here
    and monitor your usage.
  </p>

  <button
    mat-flat-button
    color="primary"
    class="documentation-button"
    (click)="onOpenDocumentation()"
  >
    Documentation
  </button>
  <!--<button
  mat-flat-button
  color="primary"
  class="documentation-button"
  (click)="onOpenFAQ()"
>
  Frequently Asked Question
</button>-->
</ng-container>
