import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AuthService } from '@verify/shared-components/services';

@Component({
    selector: 'app-home',
    imports: [CommonModule, MatCardModule, MatButtonModule],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent {
  private authService = inject(AuthService);

  get tenantId(): string {
    return this.authService.tenantId;
  }

  onOpenDocumentation(): void {
    window.open('https://joinverify.com/docs/api');
  }

  onOpenFAQ(): void {
    window.open('https://joinverify.com');
  }
}
