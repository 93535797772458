import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { SpinnerComponent } from '@verify/shared-components/components';
import { FunctionName } from '@verify/shared-components/helpers';
import { Region, Usage } from '@verify/shared-components/models';
import {
  AuthService,
  FunctionService,
} from '@verify/shared-components/services';

interface Environment {
  region: Region;
  tenant: string;
}

@Component({
  selector: 'app-usage',
  standalone: true,
  imports: [
    CommonModule,
    CanvasJSAngularChartsModule,
    SpinnerComponent,
    MatSelectModule,
    MatTableModule,
  ],
  providers: [DatePipe],
  templateUrl: './usage.component.html',
  styleUrl: './usage.component.scss',
})
export class UsageComponent implements OnInit {
  private functionService = inject(FunctionService);
  private authService = inject(AuthService);

  private usageData?: Usage[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private chart?: any;

  environments: Array<Environment> = [];
  selectedEnvironment?: Environment;

  dataSource?: Usage[];
  displayedColumns: string[] = [
    'tenant',
    'region',
    'date',
    'created',
    'removed',
    'total',
  ];

  usageChartOptions = {
    title: {
      text: 'Fingerprint service usage',
    },
    theme: 'light2',
    animationEnabled: true,
    exportEnabled: true,
    axisY: {
      maximum: 120,
      stripLines: [
        {
          value: 100,
          label: 'Quota',
          labelFontColor: '#808080',
          labelAlign: 'near',
        },
      ],
    },
    data: [
      {
        type: 'column',
        dataPoints: [{}],
      },
    ],
    render: false,
  };

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.functionService
      .call<
        { company: string },
        Usage[]
      >(FunctionName.fPrintSelectUsage, { company: this.authService.getTenantName() })
      .subscribe((usage) => {
        this.usageData = usage;
        this.usageData.forEach(({ region, tenant }) => {
          if (
            !this.environments.find(
              (environment) =>
                environment.region === region && environment.tenant === tenant,
            )
          ) {
            this.environments.push({ region, tenant });
          }
        });
        this.setEnvironment(this.environments[0]);
      });
  }

  getDate(data: Usage): Date {
    return new Date(`${data.y}-${data.m}-01`);
  }

  getChartInstance(chart: object): void {
    this.chart = chart;
  }

  setEnvironment(environment: Environment): void {
    this.selectedEnvironment = environment;
    this.dataSource = (this.usageData || [])
      .filter(
        (usage) =>
          usage.tenant === environment.tenant &&
          usage.region === environment.region,
      )
      .sort((a, b) => a.y - b.y || a.m - b.m);
    this.updateChartData(this.dataSource);
  }

  private updateChartData(data: Usage[]): void {
    const quota = data[data.length - 1].quota;
    this.usageChartOptions = {
      ...this.usageChartOptions,
      title: {
        text: `(${data[0].region}) ${data[0].tenant} fingerprinted images`,
      },
      axisY: {
        ...this.usageChartOptions.axisY,
        maximum: quota * 1.2,
        stripLines: [
          {
            ...this.usageChartOptions.axisY.stripLines[0],
            value: quota,
          },
        ],
      },
      data: [
        {
          ...this.usageChartOptions.data[0],
          dataPoints: data.map((usage) => ({
            label:
              this.datePipe.transform(this.getDate(usage), 'yyyy MMM') || '',
            y: usage.created,
          })),
        },
      ],
      render: true,
    };

    if (this.chart) {
      this.chart.options = this.usageChartOptions;
      this.chart?.render();
    }
  }
}
