import { Subject } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { SpinnerComponent } from '@verify/shared-components/components';
import { FunctionName } from '@verify/shared-components/helpers';
import { ApiKey } from '@verify/shared-components/models';
import {
  AuthService,
  FunctionService,
} from '@verify/shared-components/services';

@Component({
  selector: 'app-api-keys',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    SpinnerComponent,
    CommonModule,
  ],
  templateUrl: './api-keys.component.html',
  styleUrl: './api-keys.component.scss',
})
export class ApiKeysComponent implements OnInit, OnDestroy {
  private functionService = inject(FunctionService);
  private authService = inject(AuthService);
  private snackBar = inject(MatSnackBar);

  private destroy$ = new Subject<void>();

  displayedColumns: string[] = ['tenant', 'region', 'token', 'quota', 'expiry'];
  dataSource?: ApiKey[];

  ngOnInit(): void {
    this.functionService
      .call<
        { company: string },
        ApiKey[]
      >(FunctionName.fPrintSelectApiKeys, { company: this.authService.getTenantName() })
      .subscribe((apiKeys) => (this.dataSource = apiKeys));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCopyKey(key: string): void {
    navigator.clipboard.writeText(key);
    this.snackBar.open('Copied to clipboard', null, { duration: 3000 });
  }
}
