import { inject, Injectable } from '@angular/core';
import { FunctionName } from '@verify/shared-components/helpers';
import { CreateTenantRequest, Tenant } from '@verify/shared-components/models';
import {
  FirestoreService,
  FunctionService,
} from '@verify/shared-components/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private firestore = inject(FirestoreService);
  private functionService = inject(FunctionService);

  constructor() {}

  listTenants(): Observable<Tenant[]> {
    return this.functionService.call<void, Tenant[]>(
      FunctionName.listTenants,
      null,
    );
  }

  createTenant(data: CreateTenantRequest): Observable<void> {
    return this.functionService.call<CreateTenantRequest, void>(
      FunctionName.createTenant,
      data,
    );
  }

  updateTenant(tenant: Partial<Tenant>): Observable<void> {
    return this.functionService.call<Partial<Tenant>, void>(
      FunctionName.updateTenant,
      tenant,
    );
  }
}
