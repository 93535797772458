import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { UserNamePipe } from '@verify/shared-components/helpers';
import { User, UserRole } from '@verify/shared-components/models';
import { AuthService, HasRolePipe } from '@verify/shared-components/services';

@Component({
    selector: 'app-layout',
    imports: [
        CommonModule,
        RouterOutlet,
        RouterModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        UserNamePipe,
        MatMenuModule,
        HasRolePipe,
    ],
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  private authService = inject(AuthService);
  private router = inject(Router);

  UserRole = UserRole;

  get user(): User {
    return this.authService.currentUser;
  }

  get initials(): string {
    const { firstName, lastName, email } = this.user;
    if (firstName || lastName) {
      return `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;
    } else {
      return email?.substring(0, 1);
    }
  }

  get tenantId(): string {
    return this.authService.tenantId;
  }

  onLogout(): void {
    this.authService
      .getAuth()
      .signOut()
      .then(() => {
        this.router.navigateByUrl('/login');
      });
  }
}
