<h4>API Keys</h4>

<p>
  Use API keys to integrate Verify's fingerprint service in your own application
</p>

<ng-container *ngIf="dataSource; else loading">
  <table class="api-table" mat-table [dataSource]="dataSource!">
    <ng-container matColumnDef="tenant">
      <th mat-header-cell *matHeaderCellDef>Tenant</th>
      <td mat-cell *matCellDef="let element">
        {{ element.tenant }}
      </td>
    </ng-container>

    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef>Region</th>
      <td mat-cell *matCellDef="let element">
        {{ element.region }}
      </td>
    </ng-container>

    <ng-container matColumnDef="token">
      <th mat-header-cell *matHeaderCellDef>API Key</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex-row">
          <button mat-icon-button (click)="onCopyKey(element.token)">
            <mat-icon>content_copy</mat-icon></button
          >{{ element.token }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="quota">
      <th mat-header-cell *matHeaderCellDef>Quota</th>
      <td mat-cell *matCellDef="let element">{{ element.quota }}</td>
    </ng-container>

    <ng-container matColumnDef="expiry">
      <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.expiry | date: "mediumDate" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>

<ng-template #loading>
  <lib-spinner></lib-spinner>
</ng-template>
