import { FirebaseUIModule } from 'firebaseui-angular';

import { HttpClient, provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { MAT_ICON_DEFAULT_OPTIONS } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { firebaseUiAuthConfig } from '@verify/shared-components/helpers';
import {
  AuthService,
  initializeAuth,
} from '@verify/shared-components/services';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

registerLocaleData(localeNL, 'nl');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions(getApp(), environment.functionRegion)),
    provideFirestore(() => getFirestore(environment.database)),
    importProvidersFrom(FirebaseUIModule.forRoot(firebaseUiAuthConfig)),
    provideAnimationsAsync(),
    provideAppInitializer(() => initializeAuth(inject(AuthService))),
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: { fontSet: 'material-symbols-outlined' },
    },
  ],
};
