<h1 mat-dialog-title>{{ dialogData?.user ? "Edit" : "Add" }} user</h1>

<mat-dialog-content>
  <ng-container *ngIf="!loading; else loadingTemplate">
    <div class="form" [formGroup]="form">
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Roles</mat-label>
        <mat-select multiple formControlName="roles">
          <mat-option *ngFor="let role of roles" [value]="role">{{
            role
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <lib-spinner></lib-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="form.invalid || loading"
  >
    {{ dialogData?.user ? "Save" : "Add" }}
  </button>
</mat-dialog-actions>
