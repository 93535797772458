<h4 mat-dialog-title>Add tenant</h4>

<mat-dialog-content>
  <div class="form" [formGroup]="form" *ngIf="!loading; else loadingTemplate">
    <mat-form-field>
      <mat-label>ID</mat-label>
      <input
        matInput
        type="text"
        formControlName="id"
        [readonly]="!!dialogData?.tenant"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Features</mat-label>
      <mat-select multiple formControlName="features">
        <mat-option *ngFor="let feature of features" [value]="feature">{{
          feature
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Toggles</mat-label>
      <mat-select multiple formControlName="toggles">
        <mat-option *ngFor="let toggle of toggles" [value]="toggle">{{
          toggle
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="admin-data" formGroupName="admin" *ngIf="!dialogData?.tenant">
      <h6>Admin user</h6>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Language</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let language of languages" [value]="language">{{
            language
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Roles</mat-label>
        <mat-select multiple formControlName="roles">
          <mat-option *ngFor="let role of roles" [value]="role">{{
            role
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <lib-spinner></lib-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="form.invalid"
  >
    {{ dialogData?.tenant ? "Update" : "Add" }}
  </button>
</mat-dialog-actions>
