<div class="button-bar">
  <button
    class="add-button"
    mat-flat-button
    color="primary"
    (click)="onAddUser()"
  >
    <mat-icon>add</mat-icon>
    Add user
  </button>
</div>

<table mat-table [dataSource]="users$ | async" class="bordered-table">
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>First name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.firstName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>Last name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">
      <div class="flex-row">
        {{ element.email }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="roles">
    <th mat-header-cell *matHeaderCellDef>Roles</th>
    <td mat-cell *matCellDef="let element">
      {{ element.roles?.join(", ") }}
    </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>Created</th>
    <td mat-cell *matCellDef="let element">
      {{
        element.creationDate
          ? (element.creationDate.toDate() | timestamp: "mediumDate")
          : ""
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-button (click)="onEditUser(element)">Edit</button>
      <button mat-button (click)="onDeleteUser(element)">Delete</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
