<h4>Usage</h4>

<p>Monitor usage of Verify's API for each environment available to you</p>

<mat-form-field>
  <mat-select
    [value]="selectedEnvironment"
    (valueChange)="setEnvironment($event)"
  >
    <mat-option *ngFor="let environment of environments" [value]="environment"
      >({{ environment.region }}) {{ environment.tenant }}</mat-option
    >
  </mat-select>
</mat-form-field>

<div class="chart-container">
  <canvasjs-chart
    (chartInstance)="getChartInstance($event)"
    *ngIf="usageChartOptions.render; else loading"
    [options]="usageChartOptions"
  ></canvasjs-chart>
</div>

<ng-container *ngIf="dataSource; else loading">
  <table mat-table [dataSource]="dataSource!" class="usage-table">
    <ng-container matColumnDef="tenant">
      <th mat-header-cell *matHeaderCellDef>Tenant</th>
      <td mat-cell *matCellDef="let element">
        {{ element.tenant }}
      </td>
    </ng-container>

    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef>Region</th>
      <td mat-cell *matCellDef="let element">
        {{ element.region }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex-row">
          {{ getDate(element) | date: "MMM y" }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element">{{ element.created }}</td>
    </ng-container>

    <ng-container matColumnDef="removed">
      <th mat-header-cell *matHeaderCellDef>Removed</th>
      <td mat-cell *matCellDef="let element">{{ element.removed }}</td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let element">
        {{ element.total }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>

<ng-template #loading>
  <lib-spinner></lib-spinner>
</ng-template>
