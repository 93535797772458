import { filter, Observable, switchMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { User } from '@verify/shared-components/models';
import {
  DialogService,
  TimestampPipe,
} from '@verify/shared-components/services';

import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '@verify/shared-components/components';

import { UserService } from '../../../services';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    TimestampPipe,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent {
  private userService = inject(UserService);
  private dialogService = inject(DialogService);
  private snackBar = inject(MatSnackBar);

  users$: Observable<User[]>;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'roles',
    'created',
    'actions',
  ];

  constructor() {
    this.users$ = this.userService.getUsers();
  }

  onAddUser(): void {
    this.dialogService
      .openDialog<Partial<User>>(EditUserDialogComponent, {
        width: this.dialogService.widths.medium,
      })
      .pipe(filter((result) => !!result))
      .subscribe(() => {
        this.snackBar.open('User added successfully', null, { duration: 3000 });
      });
  }

  onEditUser(user: User): void {
    this.dialogService.openDialog<Partial<User>>(EditUserDialogComponent, {
      width: this.dialogService.widths.medium,
      data: { user },
    });
  }

  onDeleteUser(user: User): void {
    this.dialogService
      .openDialog<boolean>(ConfirmDialogComponent, {})
      .pipe(
        filter((confirmed) => !!confirmed),
        switchMap(() => this.userService.deleteUser(user.id)),
      )
      .subscribe(() => {
        this.snackBar.open('User removed successfully', null, {
          duration: 3000,
        });
      });
  }
}
