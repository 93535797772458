import { Routes } from '@angular/router';
import { LoginComponent } from '@verify/shared-components/components';
import { AuthGuard, AuthResolver } from '@verify/shared-components/helpers';
import { UserRule } from '@verify/shared-components/models';
import { hasRuleGuard } from '@verify/shared-components/services';
import { ApiKeysComponent } from './modules/api-keys/api-keys.component';
import { HomeComponent } from './modules/home/home.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { ProfileComponent } from './modules/settings/profile/profile.component';
import { UsersComponent } from './modules/settings/users/users.component';
import { TenantsComponent } from './modules/tenants/tenants.component';
import { UsageComponent } from './modules/usage/usage.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: HomeComponent,
      },
      {
        path: 'tenants',
        component: TenantsComponent,
      },
      {
        path: 'usage',
        component: UsageComponent,
      },
      {
        path: 'api-keys',
        component: ApiKeysComponent,
      },
      {
        path: 'settings',
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
          },
          {
            path: 'users',
            component: UsersComponent,
            canActivate: [hasRuleGuard],
            data: { rules: UserRule.userManagement },
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: {
      ready: AuthResolver,
    },
  },
  //   {
  //     path: '',
  //     loadComponent:
  //       () => import('./foo-list/foo-list.component')
  //         .then(mod => mod.FooListComponent),
  //     //or: loadChildren: () => import('./admin/routes').then(mod => mod.ADMIN_ROUTES)},
  //     providers: [
  //       importProvidersFrom(
  //         NgxsModule.forFeature([FooOverviewState])
  //       )
  //     ]
  //   }
];
