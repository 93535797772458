<div class="header">
  <h4>Tenants</h4>

  <button mat-stroked-button color="primary" (click)="onAddTenant()">
    <mat-icon>add</mat-icon> Add tenant
  </button>
</div>

<table class="tenant-table" mat-table [dataSource]="tenants$ | async">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">
      {{ element.id }}
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef>Creation date</th>
    <td mat-cell *matCellDef="let element">
      {{ element.creationDate | timestamp: "mediumDate" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    (click)="onOpenTenant(element)"
    class="tenant-table-row"
  ></tr>
</table>
