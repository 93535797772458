<h5 class="form-title">Personal information</h5>
<div class="container form" [formGroup]="form">
  <mat-form-field>
    <mat-label>First name</mat-label>
    <input matInput type="text" formControlName="firstName" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Last name</mat-label>
    <input matInput type="text" formControlName="lastName" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput type="text" formControlName="email" />
  </mat-form-field>

  <div class="form-footer">
    <button
      mat-flat-button
      color="primary"
      (click)="onSave()"
      [disabled]="form.invalid"
    >
      Save changes
    </button>
  </div>
</div>

<h5 class="form-title">Password</h5>
<div class="container form" [formGroup]="passwordForm">
  <mat-form-field>
    <mat-label>New password</mat-label>
    <input matInput type="password" formControlName="password" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Repeat password</mat-label>
    <input matInput type="password" formControlName="repeatPassword" />
  </mat-form-field>

  <div class="form-footer">
    <button
      mat-flat-button
      color="primary"
      (click)="onUpdatePassword()"
      [disabled]="passwordForm.invalid"
    >
      Update password
    </button>
  </div>
</div>
