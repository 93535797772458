import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [MatCardModule, MatButtonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  onOpenDocumentation(): void {
    window.open('https://joinverify.com/docs/api');
  }

  onOpenFAQ(): void {
    window.open('https://joinverify.com');
  }
}
