import { Injectable, inject } from '@angular/core';
import {
  CollectionName,
  FunctionName,
} from '@verify/shared-components/helpers';
import {
  AddUserRequest,
  AddUserResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  User,
} from '@verify/shared-components/models';
import {
  AuthService,
  FirestoreService,
  FunctionService,
} from '@verify/shared-components/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private firestore = inject(FirestoreService);
  private functionService = inject(FunctionService);
  private authService = inject(AuthService);

  constructor() {}

  getUser(userId: string): Observable<User | undefined> {
    return this.firestore.getDocument<User>(
      `${CollectionName.users}/${userId}`,
    );
  }

  getUsers(): Observable<User[]> {
    return this.firestore.getCollection<User>(CollectionName.users);
  }

  addUser(user: Partial<User>): Observable<AddUserResponse> {
    return this.functionService.call<AddUserRequest, AddUserResponse>(
      FunctionName.addUser,
      {
        tenantId: this.authService.getAuth().tenantId,
        user,
        application: 'PARTNER_PORTAL',
      },
    );
  }

  updateUser(user: User): Observable<void> {
    return this.firestore.updateDocument<User>(
      `${CollectionName.users}/${user.id}`,
      user,
    );
  }

  deleteUser(userId: string): Observable<DeleteUserResponse> {
    return this.functionService.call<DeleteUserRequest, DeleteUserResponse>(
      FunctionName.deleteUser,
      { tenantId: this.authService.getAuth().tenantId, userId },
    );
  }
}
