<h4 mat-dialog-title>{{ dialogData?.tenant ? "Edit" : "Add" }} tenant</h4>

<mat-dialog-content>
  <mat-tab-group [formGroup]="form" *ngIf="!loading; else loadingTemplate">
    <mat-tab [label]="'General'">
      <div class="form">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input
            matInput
            type="text"
            formControlName="id"
            [readonly]="!!dialogData?.tenant"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Features</mat-label>
          <mat-select multiple formControlName="features">
            <mat-option *ngFor="let feature of features" [value]="feature">{{
              feature
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Toggles</mat-label>
          <mat-select multiple formControlName="toggles">
            <mat-option *ngFor="let toggle of toggles" [value]="toggle">{{
              toggle
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <div
          class="admin-data"
          formGroupName="admin"
          *ngIf="!dialogData?.tenant"
        >
          <h6>Admin user</h6>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="email" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>First name</mat-label>
            <input matInput type="text" formControlName="firstName" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Last name</mat-label>
            <input matInput type="text" formControlName="lastName" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option
                *ngFor="let language of languages"
                [value]="language"
                >{{ language }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Roles</mat-label>
            <mat-select multiple formControlName="roles">
              <mat-option *ngFor="let role of roles" [value]="role">{{
                role
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="'Config'">
      <div class="form" formGroupName="fprintConfig">
        <h6 class="form-title">Fingerprint settings</h6>
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>API Key</mat-label>
          <input matInput type="text" formControlName="apiKey" />
        </mat-form-field>

        <mat-checkbox formControlName="increasedMaxSize"
          >Increase max size</mat-checkbox
        >
      </div>
      <div class="form" formGroupName="lythoConfig">
        <h6 class="form-title">Lytho settings</h6>
        <mat-form-field>
          <mat-label>API Key</mat-label>
          <input matInput type="text" formControlName="apiKey" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Lytho URL</mat-label>
          <input matInput type="text" formControlName="lythoUrl" />
        </mat-form-field>
      </div>
      <div class="form" formGroupName="bynderConfig">
        <h6 class="form-title">Bynder settings</h6>
        <mat-form-field>
          <mat-label>Client ID</mat-label>
          <input matInput type="text" formControlName="clientId" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Client secret</mat-label>
          <input matInput type="text" formControlName="clientSecret" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Bynder URL</mat-label>
          <input matInput type="text" formControlName="bynderUrl" />
        </mat-form-field>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-template #loadingTemplate>
    <lib-spinner></lib-spinner>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="secondary" (click)="onClose()">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="form.invalid"
  >
    {{ dialogData?.tenant ? "Update" : "Add" }}
  </button>
</mat-dialog-actions>
