import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { SpinnerComponent } from '@verify/shared-components/components';
import {
  Tenant,
  TenantFeature,
  TenantToggle,
  UserRole,
} from '@verify/shared-components/models';
import { TenantService } from '../../../services/tenant.service';

@Component({
    selector: 'app-edit-tenant-dialog',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatTabsModule,
        SpinnerComponent,
    ],
    templateUrl: './edit-tenant-dialog.component.html',
    styleUrl: './edit-tenant-dialog.component.scss'
})
export class EditTenantDialogComponent {
  private dialogRef = inject(MatDialogRef<void>);
  private tenantService = inject(TenantService);
  private formBuilder = inject(FormBuilder);

  dialogData: { tenant: Tenant } = inject(DIALOG_DATA);

  readonly form = this.formBuilder.nonNullable.group({
    id: ['', Validators.required],
    name: ['', Validators.required],
    features: new FormControl<TenantFeature[]>([]),
    toggles: new FormControl<TenantToggle[]>([]),
    admin: this.formBuilder.group({
      email: ['', [Validators.email]],
      firstName: [''],
      lastName: [''],
      language: ['NL'],
      roles: new FormControl<UserRole[]>([]),
    }),
    fprintConfig: this.formBuilder.group({
      apiKey: ['', Validators.required],
      increasedMaxSize: [false],
    }),
    lythoConfig: this.formBuilder.group({
      apiKey: [''],
      lythoUrl: [''],
    }),
    bynderConfig: this.formBuilder.group({
      bynderUrl: [''],
      clientId: [''],
      clientSecret: [''],
    }),
  });

  constructor() {
    if (this.dialogData?.tenant) {
      this.form.patchValue(this.dialogData.tenant);
    }
  }

  languages = ['EN', 'NL'];
  loading = false;

  get features(): TenantFeature[] {
    return Object.values(TenantFeature) as TenantFeature[];
  }

  get toggles(): TenantToggle[] {
    return Object.values(TenantToggle) as TenantToggle[];
  }

  get roles(): UserRole[] {
    return Object.values(UserRole) as UserRole[];
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const {
      id,
      name,
      features,
      toggles,
      admin,
      fprintConfig,
      lythoConfig,
      bynderConfig,
    } = this.form.getRawValue();
    this.loading = true;
    const data = {
      id: this.dialogData?.tenant?.id || id,
      name,
      features,
      toggles,
      ...(fprintConfig.apiKey ? { fprintConfig } : {}),
      ...(lythoConfig.lythoUrl ? { lythoConfig } : {}),
      ...(bynderConfig.bynderUrl ? { bynderConfig } : {}),
    };
    if (this.dialogData?.tenant) {
      this.tenantService.updateTenant(data).subscribe(() => {
        this.dialogRef.close();
      });
    } else {
      this.tenantService.createTenant({ ...data, admin }).subscribe(() => {
        this.dialogRef.close();
      });
    }
  }
}
