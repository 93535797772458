import { inject, Pipe, PipeTransform } from '@angular/core';

import { UserRule } from '@verify/shared-components/models';
import { AuthService } from '../auth.service';
/**
 * Returns a boolean indicating whether currently
 * logged in user has given rule
 *
 * Usage:
 *  'rule-name' | hasRule
 */
@Pipe({
  name: 'hasRule',
  standalone: true,
})
export class HasRulePipe implements PipeTransform {
  private authService: AuthService = inject(AuthService);

  transform(value: UserRule | UserRule[]): boolean {
    let rulesRequired: UserRule[];
    if (!Array.isArray(value)) {
      rulesRequired = [value];
    } else {
      rulesRequired = value;
    }
    for (const rule of rulesRequired) {
      if (!this.authService.currentUser?.rules?.includes(rule)) {
        return false;
      }
    }
    return true;
  }
}
