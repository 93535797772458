import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { Tenant } from '@verify/shared-components/models';
import {
  DialogService,
  TimestampPipe,
} from '@verify/shared-components/services';
import { Observable } from 'rxjs';
import { TenantService } from '../../services/tenant.service';
import { EditTenantDialogComponent } from './edit-tenant-dialog/edit-tenant-dialog.component';

@Component({
  selector: 'app-tenants',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    TimestampPipe,
  ],
  templateUrl: './tenants.component.html',
  styleUrl: './tenants.component.scss',
})
export class TenantsComponent {
  private authService = inject(TenantService);
  //private router = inject(Router);
  private dialogService = inject(DialogService);

  tenants$: Observable<Tenant[]>;

  displayedColumns: string[] = ['id', 'name', 'creationDate'];

  constructor() {
    this.tenants$ = this.authService.listTenants();
  }

  onOpenTenant(tenant: Tenant): void {
    this.dialogService.openDialog<void>(EditTenantDialogComponent, {
      width: this.dialogService.widths.medium,
      data: { tenant },
    });
    //this.router.navigate(['project', tenantId]);
  }

  onAddTenant(): void {
    this.dialogService.openDialog<void>(EditTenantDialogComponent, {
      width: this.dialogService.widths.medium,
      data: {},
    });
  }
}
