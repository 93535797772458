import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { User, UserRule } from '@verify/shared-components/models';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SpinnerComponent } from '@verify/shared-components/components';
import { UserService } from '../../../../services';

@Component({
  selector: 'app-edit-user-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    SpinnerComponent,
  ],
  templateUrl: './edit-user-dialog.component.html',
  styleUrl: './edit-user-dialog.component.scss',
})
export class EditUserDialogComponent {
  private dialogRef = inject(MatDialogRef<Partial<User>>);
  private formBuilder = inject(FormBuilder);
  private userService = inject(UserService);

  dialogData: { user?: User } = inject(DIALOG_DATA);

  readonly form = this.formBuilder.nonNullable.group({
    firstName: [''],
    lastName: [''],
    email: ['', [Validators.required, Validators.email]],
    roles: new FormControl<UserRule[]>([]),
  });

  loading = false;

  constructor() {
    if (this.dialogData?.user) {
      this.form.patchValue(this.dialogData.user);
    }
  }

  get roles(): UserRule[] {
    return Object.values(UserRule) as UserRule[];
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onSave(): void {
    this.loading = true;
    if (this.dialogData?.user) {
      const user = { ...this.dialogData.user, ...this.form.value };
      this.userService.updateUser(user).subscribe(() => {
        this.loading = false;
        this.dialogRef.close(true);
      });
    } else {
      const user = this.form.value;
      this.userService.addUser(user).subscribe(() => {
        this.loading = false;
        this.dialogRef.close(true);
      });
    }
  }
}
